<template>
	<div id="third_Party_order">
		<a-spin :spinning="spinning" size="large" :tip="tip">
			<div class="top">
				<a-form layout="inline">
					<a-form-item>
						<a-button icon="plus" class="margin_right20" type="primary" @click="addClick('1')">添加话题</a-button>
					</a-form-item>
				</a-form>
			</div>
			<!-- 表格 -->
			<a-table :columns="columns" :data-source="tableData" :pagination="false" :row-key="record => record.id" :loading="tableLoading">
				<span slot="enableFlag" slot-scope="enableFlag, record">
					<a-badge v-if="record.enableFlag == 0" status="warning" text="已下架" />
					<a-badge v-else status="success" text="已上架" />
				</span>
				<span slot="operation" slot-scope="operation, record">
					<a class="margin_right10" type="primary" @click="editClick(record)">编辑</a>
					<template v-if="record.enableFlag == 0">
						<a-popconfirm title="确认要上架此条数据吗？" ok-text="确定" cancel-text="取消" @confirm="isLowerShelfClick(record)">
							<a id="copy_memberNo" class="margin_right10" type="primary">上架</a>
						</a-popconfirm>
					</template>
					<template v-else>
						<a-popconfirm title="确认要下架此条数据吗？" ok-text="确定" cancel-text="取消" @confirm="isLowerShelfClick(record)">
							<a id="copy_memberNo" class="margin_right10" type="primary">下架</a>
						</a-popconfirm>
					</template>
                    <template>
						<a id="copy_memberNo" type="primary" class="margin_right10" @click="subjectClick(record)">对话管理</a>
					</template>
				</span>
			</a-table>

			<!-- 返回顶部 -->
			<a-back-top :target="targetFn" :visibilityHeight="100" />

			<a-modal v-model="visibleShow" :title="titleSize" @ok="handleOk" @cancel="handleCancel">
				<a-form-model v-if="visibleShow" ref="ruleForm" :model="form" :rules="rules">
					<a-form-model-item ref="title" label="话题名称" prop="title">
                        <a-input v-model="form.title" placeholder="请输入话题名称"></a-input>
						<!-- <a-input v-model="form.title" placeholder="请输入话题名称" /> -->
					</a-form-model-item>
					<a-form-model-item ref="effectiveDay" label="生效日期" prop="effectiveDay">
                        <a-date-picker v-model="form.effectiveDay" @change="dateTimeChange" style="width: 100%;"/>
					</a-form-model-item>
                    <a-form-model-item ref="enableFlag" label="上架设置" prop="enableFlag">
						<a-radio-group v-model="form.enableFlag" name="radioGroup">
							<a-radio :value="1">立即上架</a-radio>
							<a-radio :value="0">暂不上架</a-radio>
						</a-radio-group>
					</a-form-model-item>
				</a-form-model>
			</a-modal>
		</a-spin>
		<router-view />
	</div>
</template>

<script>
import { addTopic, topicList, updateTopic, topicEnableFlag } from "@/request/api/appletsManage";
import MyPagination from "@/components/pagination/MyPagination";
import moment from "moment";
export default {
	components: { MyPagination },
	created () {
		this.getInitFn()
	},

	data () {
		return {
			visibleShow: false,
			isErrorSize: false,
			tableLoading: false,

			tip: "",
			titleSize: '',

			spinning: false,
			activeKey: 1,
			keepLoading: false,

			bannerImg: [],

			columns: [
				{
					title: "话题名称",
					dataIndex: "title",
					key: "title",
				},
				{
					title: "话题日期",
					dataIndex: "effectiveDay",
					key: "effectiveDay",
				},
				{
					title: "状态",
					dataIndex: "enableFlag",
					key: "enableFlag",
					scopedSlots: { customRender: "enableFlag" },
				},
				{
                    title: "操作",
                    width: "200px",
					dataIndex: "operation",
					key: "operation",
					scopedSlots: { customRender: "operation" },
				},
			],
			tableData: [],

			form: {
				id: '',
				title: '',
				enableFlag: 1,
				effectiveDay: ''
			},

			rules: {
				title: [{ required: true, message: "请输入话题名称", trigger: "blur" }],
				enableFlag: [{ required: true, message: "上架设置", trigger: "change" }],
				effectiveDay :[{required: true, message: "请选择生效日期", trigger: "change"}],
			},
		};
	},

	methods: {
		moment,
		dateTimeChange (val, dateString) {
			this.form.effectiveDay = dateString
		},

		isLowerShelfClick (record) {
			topicEnableFlag({
				id: record.id,
				enableFlag: record.enableFlag == 0 ? 1 : 0
			}).then(({ code }) => {
				if (code == 200) {
					this.$message.success("操作成功！");
					this.getInitFn()
				}
			});
		},

		// 点击新增按钮
		addClick () {
			this.visibleShow = true
			this.titleSize = '新增话题'
		},

		// 编辑新增按钮
		editClick (record) {
            this.form.id = record.id,
            this.form.title = record.title
            this.form.enableFlag = record.enableFlag
            this.form.effectiveDay = record.effectiveDay
            this.titleSize = '编辑话题'
            this.visibleShow = true
        },

        // 点击对话管理按钮
        subjectClick (record) {
            this.$router.push(`/appletsManage/subjectEdit/${record.id}`)
        },

		// 分页功能切换的回调
		showSizeChangeFn (current, pageSize) {
			this.pageNo = current;
			this.pageSize = pageSize;
			this.getInitFn();
		},

		// 原属数据获取
		getInitFn () {
			topicList({
				pageSize: this.pageSize,
				pageNo: this.pageNo
			}).then(({ code, data }) => {
				this.tableLoading = false
				this.tableData = data;
			});
		},

		// 确定按钮
		handleOk () {
			let self = this
			self.$refs.ruleForm.validate((valid) => {
				if (valid) {
					if (!self.form.id) {
						addTopic({
							title: self.form.title,
							effectiveDay: self.form.effectiveDay,
							enableFlag: self.form.enableFlag,
						}).then(({ code, data }) => {
							if (code == 200) {
								self.getInitFn()
                                self.isNull()
                                self.visibleShow = false
							} else {
								self.$message.success(data.msg)
							}
						});
					} else {
						updateTopic({
							id: self.form.id,
							title: self.form.title,
							effectiveDay: self.form.effectiveDay,
							enableFlag: self.form.enableFlag,
						}).then(({ code, data }) => {
							if (code == 200) {
								self.getInitFn()
                                self.isNull()
                                self.visibleShow = false
							} else {
								self.$message.success(data.msg)
							}
						});
					}
				}
			});
		},

		handleCancel () {
			this.isNull()
		},

        isNull () {
            this.form.id = ''
			this.form.title = ''
            this.form.effectiveDay = ''
			this.form.enableFlag = 1
		},

		// 回到顶部的函数
		targetFn () {
			return document.querySelector("#third_Party_order");
		},
	},
};
</script>

<style lang="less" scoped>
#third_Party_order {
	height: 100%;
	overflow: hidden;
	overflow-y: scroll;
}

.avatar {
	width: 100px;
}
</style>

